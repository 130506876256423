import {useContext, useEffect} from "react";
import {SearchContext} from "@context";
import { useSearchParams } from "react-router-dom";

import "./Search.less";

export const Search = () => {
    const {
        visibleSearch, setVisibleSearch, setSearchFilterValues, 
        setSearchSelectedKeys, setSearchActiveKey, setSearchSorter, setSearchPagination
    } = useContext(SearchContext);
    const [searchParams] = useSearchParams();

    useEffect(() => {

        if (visibleSearch || !searchParams.get("filter")) {
            return
        }

        const searchFilterValues = JSON.parse(searchParams.get("filter") || "");
        const searchActiveKey = searchParams.get("activeKey");
        const searchSelectedKeys = JSON.parse(searchParams.get("selected") || "");
        const searchSorter = JSON.parse(searchParams.get("sorter") || "");
        const searchPagination = JSON.parse(searchParams.get("pagination") || "");

        setSearchSelectedKeys(searchSelectedKeys || []);
        setSearchPagination(searchPagination || {});
        setSearchSorter(searchSorter || {});
        setSearchFilterValues(searchFilterValues);
        setSearchActiveKey(searchActiveKey);
        setVisibleSearch(true);

    }, [searchParams])

    return null;
};