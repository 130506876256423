import React from "react";
import { Drawer } from '@components';

import clsx from "clsx";
import { KnowledgeViewItem, KnowledgeViewItemProps } from "@pages/KnowledgeView/KnowledgeViewItem";
import './KnowledgeViewDrawer.less';
import { useBreakpoint } from "@helpers";


export interface KnowledgeViewDrawerProps {
    visible?: boolean
    onClose?: () => void,
    emptyIcon?: any
    emptyMassage?: any
    itemId?: KnowledgeViewItemProps["itemId"]
    contextId?: KnowledgeViewItemProps["contextId"]
    onLinkEdit?: KnowledgeViewItemProps["onLinkEdit"]
}

const prefixCls = "page-knowledge-view-drawer"

export const KnowledgeViewDrawer: React.FC<KnowledgeViewDrawerProps> = (props: KnowledgeViewDrawerProps) => {
    const { visible, onClose, itemId, contextId, onLinkEdit, emptyIcon, emptyMassage } = props;

    const { isMobile } = useBreakpoint();

    return (
        <Drawer
            onClose={onClose}
            className={clsx(prefixCls)}
            closable={true}
            visible={visible}
            mask={true}
            size={isMobile ? 'small' : 'middle'}
        >
            <KnowledgeViewItem
                itemId={itemId}
                contextId={contextId}
                onLinkEdit={onLinkEdit}
                emptyIcon={emptyIcon}
                emptyMassage={emptyMassage}
            />
        </Drawer>
    );
}
