import React, {useState, useEffect, useCallback, useRef, useMemo} from "react";
import { Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ProjectItemProps, ControlsProps, SessionProps } from "@types";
import {translations, LOCALES} from "@translations";
import {
    ProjectsContext, ControlsContext, SessionsContext,
    UsersContext, TranslatorContext, FavoritesContext,
    UpdateKnowledgeContext, SearchContext
} from "@context";
import { ProjectsApi, SessionsApi, ControlsApi, UsersApi, KnowledgesApi } from "@api";
import {SignIn, Main, PageDenied, Page404, PageRestore, PageActiveMail} from "@pages";
import {notificationError} from "@components";
import axios from "axios";
import {getRoute, settings, removeDuplicates} from "@helpers";
import {KEY_LAST_PAGE} from "@helpers/Consts";
import { useCookies } from 'react-cookie';
import russianLocale from "moment/locale/ru";
import moment from "moment";

import "./App.less"; 

const localisation = {
    notificationErrorMassage: "ERROR!",
};

const timeoutDelayMs = 70000;
const defaultLocal = LOCALES.RUSSIAN;

const keySessionHash = "session_hash";
const keyContextHash = "x-context-hash";

const defaultSorter = {
    value: "similarity",
    direction: "desc"
};

const defaultPagination = {
    total: 0,
    current: 1,
    pageSize: 10
};

const App = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const tmCheckToken = useRef<any>(null);
    
    const [currentUser, setCurrentUser] = useState<any>();
    const [favoritesItems, setFavoritesItems] = useState<any[]>([]);
    const [favoritesItemsProject, setFavoritesItemsProject] = useState<any[]>([]);
    const [updateItems, setUpdateItems] = useState<any[]>([]);
    const [controls, setControls] = useState<ControlsProps>();
    const [currentProject, setCurrentProject] = useState<any>();
    const [step, setStep] = useState<string>("init");
    const [projects, setProjects] = useState<ProjectItemProps[]>();
    const [session, setSession] = useState<SessionProps>();
    const [cookies, setCookie, removeCookie] = useCookies<any>([keySessionHash]);

    const [visibleSearch, setVisibleSearch] = useState<boolean>();
    const [searchActiveKey, setSearchActiveKey] = useState<any>("");
    const [searchFilterValues, setSearchFilterValues] = useState<any>();
    const [searchSelectedKeys, setSearchSelectedKeys] = useState<any>([]);
    const [searchSorter, setSearchSorter] = useState<any>(defaultSorter);
    const [searchPagination, setSearchPagination] = useState<any>(defaultPagination);

    const getLoginRoute = () => [
        <Route key={2} path="/sign-in" element={<SignIn/>}/>,
        <Route
            key={3}
            path="*"
            element={<Navigate to={{pathname: "/sign-in"}}/>}
        />,
    ];

    const getChangePasswordRoute = () => [
        <Route key={1} path="/change/password/:hash" element={<PageRestore type={"restore"}/>}/>,
        ...getLoginRoute()
    ];

    const getActiveUserRoute = () => [
        <Route key={1} path="/active/user/:hash" element={<PageRestore type={"active"}/>}/>,
        ...getLoginRoute()
    ];

    const getActiveMailRoute = () => [
        <Route key={1} path="/active/mail/:hash" element={<PageActiveMail/>}/>,
        ...getLoginRoute()
    ];

    const handleRemoveSession = async () => {
        await SessionsApi.remove();
        setSessionHash(null);
        setStep("auth");
    };

    const handleCurrentProject = async (currentProject: any) => {
        localStorage.setItem(keyContextHash, currentProject.value);
        // @ts-ignore
        axios.defaults.headers.common[keyContextHash] = currentProject.value;

        const controls = await ControlsApi.get();

        setControls(controls);

        setCurrentProject(currentProject);
    };

    const loadFavorites = useCallback(async () => {
        const favorites = await KnowledgesApi.getFavoritesArticles();
        const favoritesProject = await KnowledgesApi.getFavoritesArticles({ project_id: currentProject?.value });

        favorites && setFavoritesItems(removeDuplicates(favorites, 'id'));
        favoritesProject && setFavoritesItemsProject(favoritesProject);
    }, [currentProject])

    const loadUpdateItems = useCallback(async (params?: any) => {
        const result = await KnowledgesApi.getNewArticles(params);

        if (result) setUpdateItems(result);
    }, [])

    const setSessionHash = (sessionHash: any) => {
        removeCookie(keySessionHash);
        if (sessionHash) {
            setCookie(keySessionHash, sessionHash, {
                path: '/',
                sameSite: "lax"
            });
        }
    }

    const handleChangeSession = async (sessionHash: string) => {
        setSessionHash(sessionHash);
        setStep("init");
        navigate((localStorage.getItem(KEY_LAST_PAGE) && String(localStorage.getItem(KEY_LAST_PAGE))) || getRoute('home'));
    }

    const handleClickPage404 = () => {
        setStep("done")
        navigate(getRoute('home'))
    }

    useEffect(() => {
        const checkSuccessResponse = async (response: any) => {
            const {status, data, config} = response;
            if (status === 401) {
                await handleRemoveSession();
            }

            if (config.url === `${settings.API_PREFIX}/knowledge/searching`) {
                return response;
            }

            if (data && !data.success) {
                notificationError({
                    message: localisation.notificationErrorMassage,
                    description: data.message,
                });
            }

            return response;
        };

        const checkFailureResponse = async (error: any) => {
            const {response} = error;

            if (!response) {
                return Promise.reject(error);
            }

            const {status, data} = response;

            if (status === 500 && data && !data.success) {
                notificationError({
                    message: localisation.notificationErrorMassage,
                    description: data.message,
                });
            } else if (status === 401) {
                await handleRemoveSession();
            }

            return Promise.reject(error);
        };

        axios.interceptors.response.use(checkSuccessResponse, checkFailureResponse);
    // eslint-disable-next-line
    }, []);

    useEffect(() => {

        const fetchToken = async () => {

            if (!session) {
                return
            }

            const nowDateTime = new Date().getTime();
            const sessionDateTime = new Date(session.session_end).getTime();
            const delay = sessionDateTime - (nowDateTime + timeoutDelayMs) - 100358;

            if (delay <= 0) {
                await handleRemoveSession();
                return;
            }
            tmCheckToken.current && clearTimeout(tmCheckToken.current);

            tmCheckToken.current = setTimeout(async () => {
                const response: any = await SessionsApi.update();

                if (!response) {
                    await handleRemoveSession();
                    return;
                }

                const {session_hash} = response;

                // @ts-ignore
                axios.defaults.headers.common[keySessionHash] = session_hash;
                setSessionHash(session_hash);
                setSession(response);
            }, delay);
        };

        if (step === "done") {
            fetchToken().catch((e) => console.error(e));
        }

        return () => {
            tmCheckToken.current && clearTimeout(tmCheckToken.current);
        };
    // eslint-disable-next-line
    }, [session, step])

    useEffect(() => {

        const fetchInit = async () => {
            // @ts-ignore
            axios.defaults.headers.common[keySessionHash] = undefined;
            // @ts-ignore
            axios.defaults.headers.common[keyContextHash] = undefined;

            if (window.location.pathname.indexOf("/change/password/") > -1) {
                setStep("change_password");
                return;
            }

            if (window.location.pathname.indexOf("/active/mail/") > -1) {
                setStep("active_mail");
                return;
            }

            if (window.location.pathname.indexOf("/active/user/") > -1) {
                setStep("active_user");
                return;
            }

            const sessionHash: any = cookies[keySessionHash];

            if (!sessionHash) {
                setStep("auth");
                return;
            }

            const session: SessionProps = await SessionsApi.update(sessionHash);

            if (!session) {
                await handleRemoveSession();
                return;
            }

            const {session_hash} = session;

            // @ts-ignore
            axios.defaults.headers.common[keySessionHash] = session_hash;
            setSession(session);

            const projects = await ProjectsApi.getList();

            const parts = pathname.split('/');

            const defaultProject = localStorage.getItem(keyContextHash);
            const defaultURLProject = parts.indexOf('project') > -1 && parts[parts.indexOf('project') + 1]

            if (!projects || !projects.length) {
                setStep("denied");
                return;
            }
            
            const findDefaultProject = projects.find(({ value }) => Number(value) === Number(defaultProject));
            const findDefaultURLProject = projects.find(({ value }) => Number(value) === Number(defaultURLProject));
            
            if (!findDefaultURLProject && defaultURLProject) {
                setStep("no_access");
            }

            const currentProject = findDefaultURLProject || findDefaultProject || projects[0];

            // @ts-ignore
            axios.defaults.headers.common[keyContextHash] = currentProject.value;

            const currentUser = await UsersApi.getCurrent();

            const controls = await ControlsApi.get();

            setControls(controls);

            setCurrentUser(currentUser);

            setCurrentProject(currentProject);

            setProjects(projects);


            if (findDefaultURLProject || !defaultURLProject) {
                setStep("done");
            }
        }
        

        if (step === "init") {            
            fetchInit().catch((e) => console.error(e));
        }

    // eslint-disable-next-line
    }, [cookies, step]);

    useEffect(() => {
        switch (defaultLocal) {
            case 'ru':
                moment.locale('ru', russianLocale)
                break
            case 'en':
                moment.locale('en')
                break
        }
    }, []);

    useEffect(() => {
        if (
            pathname.indexOf("sign-in") === -1 &&
            pathname.indexOf("/change/password/") === -1 &&
            cookies[keySessionHash]
        ) {
            localStorage.setItem(KEY_LAST_PAGE, window.location.href.substr(window.location.href.indexOf(window.location.origin) + window.location.origin.length));
        }
    }, [pathname, cookies])

    const dataProjectsContext = useMemo(() => ({
        setCurrentProject: handleCurrentProject,
        currentProject: currentProject,
    }), [currentProject]);


    const dataControlsContext = useMemo(() => ({
        controls: controls,
    }), [controls])

    const dataSessionsContext = useMemo(() => ({
        onRemoveSession: handleRemoveSession,
        onChangeSession: handleChangeSession,
    }), [])

    const dataTranslatorContext = useMemo(() => ({
        translator: translations[defaultLocal],
    }), [defaultLocal])

    const dataFavoritesContext = useMemo(() => ({
        favoriteItems: favoritesItems,
        favoriteItemsProject: favoritesItemsProject,
        loadFavoriteItems: loadFavorites,
    }), [favoritesItems, favoritesItemsProject])

    const dataUpdateKnowledgeContext = useMemo(() => ({
        updateItems: updateItems,
        loadUpdateItems: loadUpdateItems,
    }), [updateItems])

    const dataUsersContext = useMemo(() => ({
        currentUser: currentUser,
        setCurrentUser: setCurrentUser,
    }), [currentUser])

    const valueSearchContext = useMemo(() => ({
        visibleSearch: visibleSearch,
        setVisibleSearch: setVisibleSearch,
        searchFilterValues: searchFilterValues,
        setSearchFilterValues: setSearchFilterValues,
        searchSelectedKeys: searchSelectedKeys,
        setSearchSelectedKeys: setSearchSelectedKeys,
        searchActiveKey: searchActiveKey,
        setSearchActiveKey: setSearchActiveKey,
        searchSorter: searchSorter,
        setSearchSorter: setSearchSorter,
        searchPagination: searchPagination,
        setSearchPagination: setSearchPagination,
    }), [
        visibleSearch, searchFilterValues, searchSelectedKeys, 
        searchActiveKey, searchSorter, searchPagination
    ])

    return (
        <ProjectsContext.Provider value={dataProjectsContext}>
            <ControlsContext.Provider value={dataControlsContext}>
                <SessionsContext.Provider value={dataSessionsContext}>
                    <TranslatorContext.Provider value={dataTranslatorContext}>
                        <FavoritesContext.Provider value={dataFavoritesContext}>
                            <UpdateKnowledgeContext.Provider value={dataUpdateKnowledgeContext}>
                                <UsersContext.Provider value={dataUsersContext}>
                                    <SearchContext.Provider value={valueSearchContext}>
                                        {step === "done" && projects && controls && <Routes>
                                            <Route key={2} path="*" element={<Main />} />
                                        </Routes>}
                                    </SearchContext.Provider>
                                </UsersContext.Provider>

                                {step === "active_user" && <Routes>{getActiveUserRoute()}</Routes>}

                                {step === "change_password" && <Routes>{getChangePasswordRoute()}</Routes>}

                                {step === "active_mail" && <Routes>{getActiveMailRoute()}</Routes>}

                                {step === "no_access" && <Page404 onClick={handleClickPage404} />}

                                {step === "auth" && <Routes>{getLoginRoute()}</Routes>}

                                {step === "denied" && <PageDenied />}

                            </UpdateKnowledgeContext.Provider>
                        </FavoritesContext.Provider>
                    </TranslatorContext.Provider>
                </SessionsContext.Provider>
            </ControlsContext.Provider>
        </ProjectsContext.Provider>
    );
};

export default App;
