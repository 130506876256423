import React from "react";
import { ReactComponent as DataSvg } from "./data.svg";
import { ReactComponent as HomeSvg } from "./home.svg";
import { ReactComponent as UsersSvg } from "./users.svg";
import { ReactComponent as SearchSvg } from "./search.svg";
import { ReactComponent as SingOutSvg } from "./sing-out.svg";
import { ReactComponent as ArrowDownSvg } from "./arrow-down.svg";
import { ReactComponent as ForwardSvg } from "./forward.svg";
import { ReactComponent as BottomMenuSvg } from "./bottom-menu.svg";
import { ReactComponent as PencilSvg } from "./pencil.svg";
import { ReactComponent as CloseSvg } from "./close.svg";
import { ReactComponent as EyeSvg } from "./eye.svg";
import { ReactComponent as PlusSvg } from "./plus.svg";
import { ReactComponent as MinusSvg } from "./minus.svg";
import { ReactComponent as TrashSvg } from "./trash.svg";
import { ReactComponent as AppSvg } from "./app.svg";
import { ReactComponent as ArrowUpSvg } from "./arrow-up.svg";
import { ReactComponent as ClipSvg } from "./clip.svg";
import { ReactComponent as DownloadSvg } from "./download.svg";
import { ReactComponent as CustomRedoSvg } from "./custom-redo.svg";
import { ReactComponent as CustomUndoSvg } from "./custom-undo.svg";
import { ReactComponent as LockSvg } from "./lock.svg";
import { ReactComponent as ConfigSvg } from "./config.svg";
import { ReactComponent as UnfoldMoreSvg } from "./unfold-more.svg";
import { ReactComponent as UnfoldLessSvg } from "./unfold-less.svg";
import { ReactComponent as СalendarSvg } from "./calendar.svg";
import { ReactComponent as DmgFormatSvg } from "./dmg-format.svg";
import { ReactComponent as SliderFormatSvg } from "./slider-format.svg";
import { ReactComponent as TxtFormatSvg } from "./txt-format.svg";
import { ReactComponent as ZipFormatSvg } from "./zip-format.svg";
import { ReactComponent as DirectoryListSvg } from "./directory-list.svg";
import { ReactComponent as TreeListSvg } from "./tree-list.svg";
import { ReactComponent as NotificationSvg } from "./notification.svg";
import { ReactComponent as WarningSvg } from "./warning.svg";
import { ReactComponent as StarSvg } from "./star.svg";
import { ReactComponent as StarBorderSvg } from "./star-border.svg";
import { ReactComponent as SquareCheckSvg } from "./square-check.svg";
import { ReactComponent as FaceSvg } from "./face.svg";
import { ReactComponent as PlugSvg } from "./plug.svg";
import { ReactComponent as AttentionSvg } from "./attention.svg";
import { ReactComponent as FolderSvg } from "./folder.svg";
import { ReactComponent as RedoSvg } from "./redo.svg";
import { ReactComponent as UndoSvg } from "./undo.svg";
import { ReactComponent as SuccessSvg } from "./success.svg";
import { ReactComponent as ExpandToolsSvg } from "./expand-tools.svg";
import { ReactComponent as CheckSvg } from "./check.svg";
import { ReactComponent as RestoreSvg } from "./restore.svg";
import { ReactComponent as LinkSvg } from "./link.svg";
import { ReactComponent as CopySvg } from "./copy.svg";
import { ReactComponent as ErrorSvg } from "./error.svg";
import { ReactComponent as HelpSvg } from "./help.svg";
import { ReactComponent as PointsSvg } from "./points.svg";
import { ReactComponent as BurgerSvg } from "./burger.svg";
import { ReactComponent as UserSvg } from "./user.svg";
import { ReactComponent as HistorySvg } from "./history.svg";
import { ReactComponent as SortAscendingSvg } from "./sort-ascending.svg";
import { ReactComponent as SortDescendingSvg } from "./sort-descending.svg";

//Страны
import { ReactComponent as ChinaSvg } from "./countries/China.svg";
import { ReactComponent as SpainSvg } from "./countries/Spain.svg";
import { ReactComponent as UKSvg } from "./countries/UK.svg";
import { ReactComponent as SaudiArabiaSvg } from "./countries/Saudi-Arabia.svg";
import { ReactComponent as PortugalSvg } from "./countries/Portugal.svg";
import { ReactComponent as RussiaSvg } from "./countries/Russia.svg";
import { ReactComponent as JapanSvg } from "./countries/Japan.svg";
import { ReactComponent as GermanySvg } from "./countries/Germany.svg";
import { ReactComponent as UkraineSvg } from "./countries/Ukraine.svg";
import { ReactComponent as CzechSvg } from "./countries/Czech.svg";
import { ReactComponent as TurkeySvg } from "./countries/Turkey.svg";
import { ReactComponent as FranceSvg } from "./countries/France.svg";
import { ReactComponent as AnotherSvg } from "./countries/Another.svg";
import { ReactComponent as ChineseSvg } from "./countries/Chinese.svg";

import { Icon } from "./Icon";

export const UnfoldMoreIcon = (props: any) => <Icon IconSvg={UnfoldMoreSvg} {...props} />;
export const PointsIcon = (props: any) => <Icon IconSvg={PointsSvg} {...props} />;
export const UnfoldLessIcon = (props: any) => <Icon IconSvg={UnfoldLessSvg} {...props} />;
export const ConfigIcon = (props: any) => <Icon IconSvg={ConfigSvg} {...props} />;
export const DataIcon = (props: any) => <Icon IconSvg={DataSvg} {...props} />;
export const HomeIcon = (props: any) => <Icon IconSvg={HomeSvg} {...props} />;
export const UsersIcon = (props: any) => <Icon IconSvg={UsersSvg} {...props} />;
export const SearchIcon = (props: any) => <Icon IconSvg={SearchSvg} {...props} />;
export const SingOutIcon = (props: any) => <Icon IconSvg={SingOutSvg} {...props} />;
export const ArrowDownIcon = (props: any) => <Icon IconSvg={ArrowDownSvg} {...props} />;
export const ForwardIcon = (props: any) => <Icon IconSvg={ForwardSvg} {...props} />;
export const BottomMenuIcon = (props: any) => <Icon IconSvg={BottomMenuSvg} {...props} />;
export const PencilIcon = (props: any) => <Icon IconSvg={PencilSvg} {...props} />;
export const CloseIcon = (props: any) => <Icon IconSvg={CloseSvg} {...props} />;
export const EyeIcon = (props: any) => <Icon IconSvg={EyeSvg} {...props} />;
export const PlusIcon = (props: any) => <Icon IconSvg={PlusSvg} {...props} />;
export const MinusIcon = (props: any) => <Icon IconSvg={MinusSvg} {...props} />;
export const TrashIcon = (props: any) => <Icon IconSvg={TrashSvg} {...props} />;
export const AppIcon = (props: any) => <Icon IconSvg={AppSvg} {...props} />;
export const LockIcon = (props: any) => <Icon IconSvg={LockSvg} {...props} />;
export const ArrowUpIcon = (props: any) => <Icon IconSvg={ArrowUpSvg} {...props} />;
export const ClipIcon = (props: any) => <Icon IconSvg={ClipSvg} {...props} />;
export const DownloadIcon = (props: any) => <Icon IconSvg={DownloadSvg} {...props} />;
export const CustomRedoIcon = (props: any) => <Icon IconSvg={CustomRedoSvg} {...props} />;
export const CustomUndoIcon = (props: any) => <Icon IconSvg={CustomUndoSvg} {...props} />;
export const СalendarIcon = (props: any) => <Icon IconSvg={СalendarSvg} {...props} />;
export const DmgFormatIcon = (props: any) => <Icon IconSvg={DmgFormatSvg} {...props} />;
export const SliderFormatIcon = (props: any) => <Icon IconSvg={SliderFormatSvg} {...props} />;
export const TxtFormatIcon = (props: any) => <Icon IconSvg={TxtFormatSvg} {...props} />;
export const ZipFormatIcon = (props: any) => <Icon IconSvg={ZipFormatSvg} {...props} />;
export const DirectoryListIcon = (props: any) => <Icon IconSvg={DirectoryListSvg} {...props} />;
export const TreeListIcon = (props: any) => <Icon IconSvg={TreeListSvg} {...props} />;
export const NotificationIcon = (props: any) => <Icon IconSvg={NotificationSvg} {...props} />;
export const WarningIcon = (props: any) => <Icon IconSvg={WarningSvg} {...props} />;
export const StarIcon = (props: any) => <Icon IconSvg={StarSvg} {...props} />;
export const StarBorderIcon = (props: any) => <Icon IconSvg={StarBorderSvg} {...props} />;
export const SquareCheckIcon = (props: any) => <Icon IconSvg={SquareCheckSvg} {...props} />;
export const FaceIcon = (props: any) => <Icon IconSvg={FaceSvg} {...props} />;
export const Plug404Icon = (props: any) => <Icon IconSvg={PlugSvg} {...props} />;
export const AttentionIcon = (props: any) => <Icon IconSvg={AttentionSvg} {...props} />;
export const FolderIcon = (props: any) => <Icon IconSvg={FolderSvg} {...props} />;
export const RedoIcon = (props: any) => <Icon IconSvg={RedoSvg} {...props} />;
export const UndoIcon = (props: any) => <Icon IconSvg={UndoSvg} {...props} />;
export const SuccessIcon = (props: any) => <Icon IconSvg={SuccessSvg} {...props} />;
export const ExpandToolsIcon = (props: any) => <Icon IconSvg={ExpandToolsSvg} {...props} />;
export const CheckIcon = (props: any) => <Icon IconSvg={CheckSvg} {...props} />;
export const RestoreIcon = (props: any) => <Icon IconSvg={RestoreSvg} {...props} />;
export const LinkIcon = (props: any) => <Icon IconSvg={LinkSvg} {...props} />;
export const CopyIcon = (props: any) => <Icon IconSvg={CopySvg} {...props} />;
export const ErrorIcon = (props: any) => <Icon IconSvg={ErrorSvg} {...props} />;
export const BurgerIcon = (props: any) => <Icon IconSvg={BurgerSvg} {...props} />;
export const UserIcon = (props: any) => <Icon IconSvg={UserSvg} {...props} />;
export const HistoryIcon = (props: any) => <Icon IconSvg={HistorySvg} {...props} />;
export const SortAscendingIcon = (props: any) => <Icon IconSvg={SortAscendingSvg} {...props} />;
export const SortDescendingIcon = (props: any) => <Icon IconSvg={SortDescendingSvg} {...props} />;

// Страны
export const ChinaIcon = (props: any) => <Icon IconSvg={ChinaSvg} {...props} />;
export const SpainIcon = (props: any) => <Icon IconSvg={SpainSvg} {...props} />;
export const UKIcon = (props: any) => <Icon IconSvg={UKSvg} {...props} />;
export const SaudiArabiaIcon = (props: any) => <Icon IconSvg={SaudiArabiaSvg} {...props} />;
export const PortugalIcon = (props: any) => <Icon IconSvg={PortugalSvg} {...props} />;
export const RussiaIcon = (props: any) => <Icon IconSvg={RussiaSvg} {...props} />;
export const JapanIcon = (props: any) => <Icon IconSvg={JapanSvg} {...props} />;
export const GermanyIcon = (props: any) => <Icon IconSvg={GermanySvg} {...props} />;
export const UkraineIcon = (props: any) => <Icon IconSvg={UkraineSvg} {...props} />;
export const CzechIcon = (props: any) => <Icon IconSvg={CzechSvg} {...props} />;
export const TurkeyIcon = (props: any) => <Icon IconSvg={TurkeySvg} {...props} />;
export const FranceIcon = (props: any) => <Icon IconSvg={FranceSvg} {...props} />;
export const AnotherCountryIcon = (props: any) => <Icon IconSvg={AnotherSvg} {...props} />;
export const ChineseIcon = (props: any) => <Icon IconSvg={ChineseSvg} {...props} />;
export const HelpIcon = (props: any) => <Icon IconSvg={HelpSvg} {...props} />;