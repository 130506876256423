import React, {useState, useEffect, useContext} from "react";
import {getRoute, useBreakpoint, useTranslator} from "@helpers";
import {Drawer, KnowledgeList, Tooltip, Select, Pagination, TypographyText} from "@components";
import {KnowledgeViewItem, KnowledgeViewDrawer} from "@pages";
import {SortAscendingIcon, SortDescendingIcon} from "@icons";
import {SearchContext, ProjectsContext} from "@context";
import { useNavigate } from 'react-router-dom';
import { WarningIcon, AttentionIcon } from "@assets/icons";

import {CloseIcon} from "@icons";
import { Tabs } from 'antd';
import clsx from "clsx";

import "./MainSearchResult.less";

export interface MainSearchResultProps {
}

const prefixCls = "page-main-search-result";

export const MainSearchResult = React.memo((props: any) => {
    const {context, dataItems} = props;

    const navigate = useNavigate();
    const {getLocalisation} = useTranslator("main");
    const {currentProject} = useContext(ProjectsContext);

    const { 
        visibleSearch, setVisibleSearch, searchActiveKey, setSearchActiveKey,
        searchSelectedKeys, setSearchSelectedKeys, searchSorter, setSearchSorter,
        searchPagination, setSearchPagination
    } = useContext(SearchContext);

    const { isMobile, isTablet } = useBreakpoint();
    const [contextId, setContextId] = useState<any>(null);
    const [redirectUrl, setRedirectUrl] = useState<any>(null);
    const [visibleKnowledgeViewId, setVisibleKnowledgeViewId] = useState<any>(null);

    const handleClose = () => {
        setVisibleSearch(false);
    }

    const handleEditTab = (key: any) => {
        const newSelectedItems = searchSelectedKeys.filter(({id}: any) => `${id}` !== key);

        if (key === searchActiveKey) {
            setSearchActiveKey(newSelectedItems[0] && `${newSelectedItems[0]}`);
        }

        setSearchSelectedKeys(newSelectedItems)
    };

    const getTooltip = (id: any) => {
        const item = dataItems?.find((item: any) => id === item.id);

        return item?.title
    }

    const handleChangeTab = (searchActiveKey: any) => {
        setSearchActiveKey(searchActiveKey)
    };

    const handleKnowledgeClick = (id: any, e: MouseEvent) => {
        if (e.ctrlKey || e.button === 1) {
            window.open(getRoute('knowledgeView', { 'project': contextId, 'item': id }))
            return;
        }

        setSearchActiveKey(`${id}`);

        const item = dataItems?.find((item: any) => id === item.id);

        setSearchSelectedKeys((searchSelectedKeys: any) => [{
            id: item.id, 
            title: item.title
        }, ...searchSelectedKeys.filter(({id}: any) => id !== item.id)].slice(0, 15));


        isTablet && setVisibleKnowledgeViewId(id);
    }

    const handleLinkEdit = () => {
        setVisibleSearch?.()
        setVisibleKnowledgeViewId(undefined);
    }

    const handleTabClick = (visibleKnowledgeViewId: any) => {

        if (!isTablet) {
            return
        }

        setVisibleKnowledgeViewId(visibleKnowledgeViewId);
    }

    const getSortOptions = () => [
        {
            value: "similarity",
            label: getLocalisation("similarity")
        },
        {
            value: "title",
            label: getLocalisation("title")
        },
        {
            value: "modify_date",
            label: getLocalisation("modify_date")
        }
    ];

    const handleChangeSort = (value: any) => {
        setSearchSorter((sorter: any) => ({...sorter, value}))
    }

    const handleChangeSortDirection = () => {
        setSearchSorter((sorter: any) => ({...sorter, direction: sorter?.direction === "desc" ? "asc" : "desc"}))
    }

    const handleChangePagination = (current: any) => {
        setSearchPagination((pagination: any) => ({...pagination, current}))
    }

    const getStartPaginationIndex = () => (((searchPagination?.current || 0) - 1) * searchPagination?.pageSize)

    const getEndPaginationIndex = () => ((searchPagination?.current || 0) * searchPagination?.pageSize)

    useEffect(() => {
        setContextId(context?.project_id)
    }, [context?.project_id])

    useEffect(() => {
        setSearchPagination((pagination: any) => ({...pagination, total: dataItems?.length || 0}))
    }, [dataItems])

    useEffect(() => {
        if (visibleSearch) {
            setRedirectUrl(window.location.pathname !== getRoute('search') && window.location.pathname)
        }
    }, [visibleSearch]);

    useEffect(() => {

        if (!visibleSearch && window.location.pathname.includes(getRoute('search'))) {
            if (!redirectUrl) {
                navigate(getRoute("home"));
            }
            else {
                window.history.replaceState(null, "", redirectUrl);
            }
        }

    }, [visibleSearch]);

    return (<>
        <Drawer
            mask={true}
            destroyOnClose={false}
            closable={!(isTablet || isMobile)}
            placement="top"
            visible={visibleSearch && !!dataItems}
            onClose={handleClose}
            size='large'
            className={clsx(`${prefixCls}-drawer`, {
                [`${prefixCls}-drawer-tablet`]: isTablet,
                [`${prefixCls}-drawer-mobile`]: isMobile
            })}
        >
            <section className={clsx(`${prefixCls}-list`)}>

                {dataItems && !!dataItems.length && <div className={clsx(`${prefixCls}-toolbar`)}>

                    <Select
                        value={searchSorter?.value}
                        onChange={handleChangeSort}
                        placement="bottomRight"
                        options={getSortOptions()}
                    />

                    <div 
                        className={clsx(`${prefixCls}-toolbar-icon`)}
                        onClick={handleChangeSortDirection}
                    >
                        {searchSorter?.direction === 'asc' && <SortAscendingIcon/>}
                        {searchSorter?.direction === 'desc' && <SortDescendingIcon/>}
                    </div>

                </div>}

                {dataItems && <KnowledgeList
                    onClick={handleKnowledgeClick}
                    size="small"
                    items={dataItems?.slice(getStartPaginationIndex(), getEndPaginationIndex())}
                />}

                {dataItems && !!dataItems.length && <div className={clsx(`${prefixCls}-pagination`)}>
                    <Pagination
                        simple={true}
                        onChange={handleChangePagination}
                        current={searchPagination?.current}
                        total={searchPagination?.total}
                        pageSize={searchPagination?.pageSize}
                    />
                    <TypographyText level="small">{`${getStartPaginationIndex() + 1} - ${getEndPaginationIndex()} из ${searchPagination?.total} записей`}</TypographyText>
                </div>}

            </section>

            <section className={clsx(`${prefixCls}-view`)}>
                {contextId && searchSelectedKeys && !!searchSelectedKeys.length && <Tabs
                    hideAdd
                    size="small"
                    type="editable-card"
                    activeKey={searchActiveKey}
                    onChange={handleChangeTab}
                    onEdit={handleEditTab}
                    onTabClick={handleTabClick}
                    tabPosition="left"
                >
                    {searchSelectedKeys.map((item: any) => {
                        let id = item, 
                            title = getTooltip(item);

                        if (typeof item === 'object') {
                            id = item.id;
                            title = item.title;
                        }

                        return (
                            <Tabs.TabPane
                                key={`${id}`}
                                tab={<Tooltip 
                                    title={title}
                                    placement="left"
                                >{`ID: ${id}`}</Tooltip>}
                                closable={true}
                                closeIcon={<CloseIcon />}
                            >
                                {!isTablet && <KnowledgeViewItem
                                    itemId={id}
                                    contextId={contextId}
                                    onLinkEdit={handleLinkEdit}
                                    emptyIcon={<></>}
                                    emptyMassage={<TypographyText type="secondary" level="middle">{`Статья недоступна в проекте "${currentProject?.label}"`}</TypographyText>}

                                />}
                            </Tabs.TabPane>
                        )
                    })}

                </Tabs>}
            </section>


        </Drawer>

        <KnowledgeViewDrawer
            visible={isTablet && !!visibleKnowledgeViewId}
            onClose={() => setVisibleKnowledgeViewId(false)}
            itemId={visibleKnowledgeViewId}
            contextId={contextId}
            onLinkEdit={handleLinkEdit}
            emptyIcon={<></>}
            emptyMassage={<TypographyText type="secondary" level="middle">{`Статья недоступна в проекте "${currentProject?.label}"`}</TypographyText>}
        />
    </>);
});