import React from 'react';
import { Pagination as AntdPagination, PaginationProps as AntdPaginationProps } from 'antd';
import './Pagination.less'
import { ButtonText } from '@components';
import { ArrowDownIcon } from '@assets/icons';


export interface PaginationProps {
    defaultCurrent?: AntdPaginationProps['defaultCurrent'];
    showQuickJumper?: AntdPaginationProps['showQuickJumper'];
    showSizeChanger?: AntdPaginationProps['showSizeChanger'];
    simple?: AntdPaginationProps['simple'];
    total?: AntdPaginationProps['total'];
    pageSize?: AntdPaginationProps['pageSize'];
    onChange?: AntdPaginationProps['onChange'];
    current?: AntdPaginationProps['current'];
}

const prefixCls = "cmp-pagination";

export const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
    const { defaultCurrent = 1, total, pageSize, onChange, current, showQuickJumper, showSizeChanger, simple } = props;


    const itemRender = (page: any, type: any, originalElement: any) => {
        if (type === "prev") return <ButtonText beforeIcon={<ArrowDownIcon />} />;
        if (type === "next") return <ButtonText beforeIcon={<ArrowDownIcon />} />;
        if (type === "page") return <ButtonText>{page}</ButtonText>;
        return originalElement;
    };

    return (
        <AntdPagination
            simple={simple}
            showSizeChanger={showSizeChanger}
            showQuickJumper={showQuickJumper}
            prefixCls={prefixCls}
            showTitle={false}
            current={current}
            defaultCurrent={defaultCurrent}
            total={total}
            pageSize={pageSize}
            onChange={onChange}
            itemRender={itemRender}
        />
    )
}