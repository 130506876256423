import {useState, useEffect, useRef, useContext} from "react";
import {ButtonText, InputSearch, Tooltip, Space, CopyButton, Button} from "@components";
import {useBreakpoint, useTranslator} from "@helpers";
import {ExpandToolsIcon, CloseIcon, SearchIcon} from "@assets/icons";
import {ProjectsContext} from "@context";
import {MainSearchFilter} from "./MainSearchFilter";
import {MainSearchResult} from "./MainSearchResult";
import {SearchContext} from "@context";
import {KnowledgesApi} from "@api";
import clsx from "clsx";

import "./MainSearch.less";

const prefixCls = "page-main-search";
const minChars = 3;

const defaultFormValues: any = {
    date: "all",
    languages: 'all',
    sections: [],
    categories: [],
    keywords: [],
    tags: []
}

export const MainSearch = () => {

    const {getLocalisation} = useTranslator("main");
    const {
        visibleSearch, setVisibleSearch, searchFilterValues, 
        searchSelectedKeys, searchActiveKey, searchSorter, searchPagination, setSearchPagination
    } = useContext(SearchContext);

    const refTimer = useRef<any>(null);
    const inputRef = useRef<any>(null);

    const {isTablet} = useBreakpoint();
    const {currentProject} = useContext(ProjectsContext);

    const [changeFormFields, setChangeFormFields] = useState<boolean>();
    const [formVisible, setFormVisible] = useState<boolean>(false);

    const [search, setSearch] = useState<string>('');
    const [loading, setLoading] = useState<any>(false);
    const [focused, setFocused] = useState<boolean>(false);
    const [values, setValues] = useState<any>();
    const [dataItems, setDataItems] = useState<any>();
    const [copySearchLink, setCopySearchLink] = useState<any>();
    const [currentContext, setCurrentContext] = useState<any>();
    const [showTabletSearch, setShowTabletSearch] = useState<boolean>();

    const handleCloseForm = () => {
        setFormVisible(false);
    }

    const handleClickReset = () => {
        setSearch("");
        isTablet && setVisibleSearch(false);
        setShowTabletSearch(false);
    }

    const handleResetForm = (form: any) => {
        form.setFieldsValue({...defaultFormValues});
    }

    const handleChange = (event: any) => {
        const { value } = event.target;
        setSearch(value);
    }

    const handleFocus = () => {
        setFocused(true);
    }

    const handleBlur = () => {
        setFocused(false);
        isTablet && !search && !visibleSearch && setShowTabletSearch(false)
    }

    const getKeyObject = (values: any) => {
        const _values = Object.keys(values)
            .sort((a: any, b: any) => a - b)
            .reduce((acc: any, key: any,) => ({...acc, [key]: values[key]}), {});

        return JSON.stringify(_values)
    }

    const handleSubmit = (newValues: any) => {
        const mergeValue = {...values, ...newValues};

        setChangeFormFields(checkDiffValues(mergeValue));

        setValues(mergeValue);
        setFormVisible(false);
    }

    const handlePressEnter = (event: any) => {
        const { value } = event.target;
        setValues({...values, search: value, __trigger: (new Date()).getTime()});
    }

    const handleShowTableSearch = () => {
        setShowTabletSearch(true);
    }

    useEffect(() => {
        if (visibleSearch && currentContext) {
            const {sorter, ...filter} = currentContext;
            
            const copySearchLink = encodeURI(`/search?pagination=${JSON.stringify(searchPagination || {})}&activeKey=${searchActiveKey}&sorter=${JSON.stringify(sorter || {})}&selected=${JSON.stringify(searchSelectedKeys || [])}&filter=${JSON.stringify(filter)}`);
            setCopySearchLink(`${window.location.origin}${copySearchLink}`);
            window.history.replaceState(null, "", copySearchLink);
        }

    }, [visibleSearch, currentContext, searchSelectedKeys, searchActiveKey, searchPagination])

    useEffect(() => {

        const fetchData = async () => {
            const currentContext = {
                ...values, 
                project_id: currentProject?.value,
                sorter: searchSorter
            };
            const loadingKey = (new Date()).getTime();

            setLoading(loadingKey);

            const {search, project_id, date, languages, sections, categories, keywords, tags, sorter} = currentContext;

            const currentFilterValues = {
                search, project_id, date,
                languages: languages === 'all' ? null : languages,
                sections: sections?.join(","),
                groups: categories?.join(","),
                keywords: keywords?.join(','),
                sort_column: sorter?.value,
                sort_direct: sorter?.direction,
                tags: tags?.join(",")
            };

            const searchResultData = await KnowledgesApi.search(currentFilterValues);

            setLoading((value: any) => loadingKey === value ? false : value);

            if (searchResultData) {
                setSearchPagination((searchPagination: any) => ({...searchPagination, current: 1}))
                setCurrentContext(currentContext);
                setDataItems((searchResultData || []));
            }
        }

        if (visibleSearch && !!values && currentProject?.value) {
            fetchData().catch(console.error);
        }

    }, [values, currentProject?.value, searchSorter])

    useEffect(() => {

        if (currentProject?.value === values?.project_id) {
            return
        }

        if (values) {
            setChangeFormFields(checkDiffValues(values));
        }

    }, [currentProject, values])

    useEffect(() => {

        refTimer.current && clearTimeout(refTimer.current);

        refTimer.current = setTimeout(async () => {

            if (search.length >= minChars && focused) {

                setVisibleSearch(true);

                const currentValues = values || {};
                const mergeValue = {...defaultFormValues, ...currentValues, search};

                if (getKeyObject(currentValues) === getKeyObject(mergeValue)) {
                    return
                }

                setValues(mergeValue);
            }
        }, 500);

    }, [focused, search]);

    useEffect(() => {
        if (searchFilterValues && !values) {
            setSearch(searchFilterValues?.search)
            setValues({...defaultFormValues, ...searchFilterValues});
            setChangeFormFields(checkDiffValues(searchFilterValues));
        }
    }, [searchFilterValues])

    useEffect(() => {
        setShowTabletSearch(isTablet && visibleSearch);
    }, [isTablet, visibleSearch])

    const checkDiffValues = (values: any) => {
        const checkValues = Object.keys(defaultFormValues)
            .filter((key: any) => !!values[key] && !!defaultFormValues[key])
            .reduce((acc: any, key: any) => ({...acc, [key]: values[key]}), {});

        return getKeyObject(checkValues) !== getKeyObject(defaultFormValues)    
    }

    return (
        <div className={clsx(`${prefixCls}`, {
            [`${prefixCls}-tablet`]: isTablet,
            [`${prefixCls}-show-search-tablet`]: showTabletSearch,
        })}>

            {isTablet && !showTabletSearch && <Button
                size="large"
                afterIcon={<SearchIcon/>}
                type='dropdown'
                onClick={handleShowTableSearch}
            />}

            <InputSearch
                loading={!!loading}
                size="large"
                value={search}
                onChange={handleChange}
                onPressEnter={handlePressEnter}
                className={clsx(`${prefixCls}-input`)}
                placeholder={getLocalisation('search_article')}
                onBlur={handleBlur}
                onFocus={handleFocus}
                ref={inputRef}
                suffix={<Space>
                    {(!!search || visibleSearch && isTablet && showTabletSearch) && <ButtonText
                        size='large'
                        beforeIcon={<CloseIcon />}
                        onClick={handleClickReset}
                    />}
                    {dataItems && visibleSearch && <Tooltip
                        placement="right"
                        title={getLocalisation('advanced_filter')}
                    >
                        <ButtonText
                            size="large"
                            onClick={() => setFormVisible(true)}
                            className={clsx(`${prefixCls}-button`)}
                            beforeIcon={<>
                                <ExpandToolsIcon />
                                {changeFormFields && <div className={clsx(`${prefixCls}-check`)} />}
                            </>}
                        />
                    </Tooltip>}
                    {dataItems && visibleSearch && copySearchLink && <CopyButton
                        tooltip={getLocalisation('copy_url_search')}
                        placement="right"
                        content={copySearchLink}
                    />}
                </Space>}
            />

            <MainSearchFilter
                onSubmit={handleSubmit}
                initialValues={values}
                visible={formVisible}
                onClose={handleCloseForm}
                onReset={handleResetForm}
            />

            <MainSearchResult
                dataItems={dataItems}
                context={currentContext}
            />

        </div>
    );
};
