import React from "react";
import { EmptyRequest } from "@components";
import { useTranslator } from "@helpers";

import "./KnowledgeViewEmpty.less";

export interface KnowledgeViewEmptyProps {
    itemId?: any,
    massage?: any,
    icon?: any,
}

export const KnowledgeViewEmpty = (props: KnowledgeViewEmptyProps) => {
    const { itemId, massage, icon } = props;

    const { getLocalisation } = useTranslator("knowledge");

    return (
        <EmptyRequest 
            icon={icon}
            massage={massage || getLocalisation("not_published", {
                ["{id}"]: itemId
            })} 
        />
    );
};
